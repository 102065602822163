import moment from "moment"

function decodeURL(encodedURL) {
    try {
        return decodeURIComponent(encodedURL);
    } catch (error) {
        console.error("Erreur lors du décodage de l'URL :", error);
        return "";
    }
}

const globalError = (error, user_id) => {
    console.log("ERROR [userError] =>", error)
    if (error.includes("user_unique_email")) {
        return "Cette adresse mail est déjà utilisée"
    } else if (error.includes("the email should be a valid email")) {
        return "Cet email n'est pas valide"
    } else if (error.includes("the firstname is invalid")) {
        return "Le prénom doit être écrit en alphanumerique"
    } else if (error.includes("the firstname is required")) {
        return "Le prénom est obligatoire"
    } else if (error.includes("the lastname is required")) {
        return "Le nom est obligatoire"
    } else if (error.includes("the lastname should be minimum than 2")) {
        return "Le nom doit avoir minimum 2 caractéres"
    } else if (error.includes("the firstname should be minimum than 2")) {
        return "Le prénom doit avoir minimum 2 caractéres"
    } else if (error.includes("the email is required")) {
        return "L'email est obligatoire"
    } else if (error.includes("the phone is required")) {
        return "Le numéro de téléphone est obligatoire"
    } else if (error.includes("duplicate key value violates unique constraint") && !error.includes("borrower_unique_email")) {
        return "Un des champs du formulaire n'est pas unique"
    }
    else if (error.includes("borrower_unique_email")) {
        return "Mail déjà utilisé sur une autre fiche découverte. Merci de saisir une autre adresse mail ou de dupliquer la fiche initiale"
    }
    else if (error.includes("the lastname is invalid")) {
        return "Le nom doit être écrit en alphanumerique"
    }
    else if (error.includes("BAD_PASSWORD")) {
        return "Le mot de passe est incorrect"
    }
    else if (error.includes("NO ADVISER")) {
        return "Aucun conseiller correspondant au pôle du prospect"
    }
    else if (error.includes("EMAIL_DOEST_NOT_EXIST")) {
        return "Cet email n'existe pas"
    }
    else if (error.includes("PREFIX IS MISSING")) {
        return "Identifiants Cifacil incorrects"
    }
    else if (error.includes("PREFIX IS NOT VALID")) {
        return "Identifiants Cifacil incorrects"
    }

    else if (error.includes("the phone is required")) {
        return "Veuillez entrer ou corrgier le numéro de téléphone"
    }
    else if (error.includes("POSTAL CODE IS MISSING")) {
        return "Le code postal est manquant."
    }
    else if (error.includes("invalid UUID length: 0")) {
        return "Vous avez un champ selection vide"
    }
    else if (error.includes("MUST BE E164 FORMAT")) {
        return "Le format du téléphone est incorrecte.Vous devez choisir le prefix et entrer le 0 ex: 0692XXXXXX"
    }
    else if (error.includes("CreateLead fic06 - Nombre de clients > 2")) {
        return "Cifacil autorise au maximum 2 emprunteurs "
    }
    else if (error.includes("the is required")) {
        return null
    }
    else if (error.includes("PHONE ALREADY USED")) {
        return "Numéro de téléphone utilisé par un autre prospect "
    }
    else if (error.includes("MAIL ALREADY USED")) {
        return "Mail utilisé par un autre prospect "
    }
    else if (error.includes("adviser_has_prospect_adviser_id_fkey")) {
        return "Vous devez sélectionner un conseiller"
    }
    else if (error.includes("USER HAS ALREADY PRET PRO")) {
        return "L'utilisateur est déjà lié à des Agences Prêt pro"
    }
    else if (error.includes("USER HAS ALREADY IMMO")) {
        return "L'utilisateur est déjà lié à des Agences Immo"
    }
    else if (error.includes("USER HAS ALREADY RAC")) {
        return "L'utilisateur est déjà lié à des Agences RAC"
    }
    else if (error.includes("ACCOUNT_IS_NOT_ACTIVE")) {
        return "Ce compte n'est pas activé"
    }
    else if (error.includes("the phone should be e164 formatted phone number")) {
        return "Le numéro de téléphone n'a pas le bon format"
    }
    else if (error.includes("is required")) {
        return "Un champ requis n'a pas été complété"
    }
    else if (error.includes("to perform this action")) {
        return "Vous n'avez pas le bon rôle pour réaliser cette action"
    }
    else if (error.includes("ALREADY_MAIN_AGENCY")) {
        return "Déjà l'Agence principale"
    }
    else if (error.includes("CONFLICT_RANGE_WITH")) {
        return "Ces codes postaux sont déjà gérés par une autre Agence"
    }
    else if (error.includes("ADVISER_ID ALREADY IN DISTRIBUTION")) {
        return "Le conseiller est déjà dans la distribution"
    }
    else if (error.includes("STATUS IS NOT VALID")) {
        return "Le statut n'est pas valide"
    }
    else if (error.includes("YOU NEED TO ADD A DOCUMENT BEFORE")) {
        return "Vous devez ajouter un document avant"
    }
    else if (error.includes("YOU NEED TO CHOOSE A MOTIVE OR ADD A COMMENT")) {
        return "Vous devez ajouter un motif on un commentaire"
    }
    else if (error.includes("FILE ALREADY ADDED")) {
        return "fichier déjà ajouté"
    }
    else if (error.includes("FILE DOES NOT EXIST")) {
        return "Ce fichier n'existe pas"
    }
    else if (error.includes("DISCOVERY IS NOT LINKED TO THiRD PARTY LOGIN")) {
        return "Cette fiche découverte n'est lié à aucun login cifacil"
    }
    else if (error.includes("NO INTERNET CONNECTION")) {
        return "L'identifiant ou mot de passe cifacil est incorrecte"
    }
    else if (error.includes("THIRD PARTY LOGIN ID IS MISSING")) {
        return "Il manque l'identifiant cifacil"
    }
    else if (error.includes("LOGIN FOR AUTHENTICATION IS MISSING")) {
        return "Login cifacil manquant"
    }
    else if (error.includes("PASSWORD FOR AUTHENTICATION IS MISSING")) {
        return "Mot de passe cifacil manquant"
    }
    else if (error.includes("DATA FILE_ID IS MISSING")) {
        return "Numéro de dossier cifacil manquant"
    }
    else if (error.includes("DOSSIER DOES NOT EXIST")) {
        return "Ce dossier cifacil n'existe pas"
    }
    else if (error.includes("PROSPECT ALREADY PROCESS")) {
        return "Prospect déjà supprimé"
    }
    else if (error.includes("YOU MUST IMPORT EMAILS")) {
        return "Vous devez importer les emails"
    }
    else if (error.includes("YOU MUST CHOOSE AN AGENCy")) {
        return "Vous devez choisir une Agence"
    }
    else if (error.includes("ERROR SENDING CAMPAIGN")) {
        return "La newsletter n'a pas pu être envoyée"
    }
    else if (error.includes("ALREADY_SENT")) {
        return "La newsletter a déjà été envoyée"
    }
    else if (error.includes("DOMAIN ALREADY EXIST")) {
        return "Ce nom de domaine existe déjà"
    }
    else if (error.includes("YOU MUST DEFINE OR SELECT A SOCIETY")) {
        return "Vous devez entrer une société ou en sélectionner une "
    }
    else if (error.includes("IS NOT YOUR PARTNER")) {
        return "Ce n'est pas votre partenaire"
    }
    else if (error.includes("IS NOT YOUR PROFILE")) {
        return "Ce n'est pas votre profile"
    }
    else if (error.includes("YOU MUST CHOOSE AN ADIVSER")) {
        return "Vous devez choisir un conseiller"
    }
    else if (error.includes("SPONSOR_NO_POLE_ADVISER AGENCY AND ADVISER IS MISSING")) {
        return "il manque l'agence et le conseiller, le conseiller du parrain ne gére pas ce type de prospect "
    }
    else if (error.includes("AGENCY AND ADVISER IS MISSING")) {
        return "il manque l'agence et le conseiller"
    }
    else if (error.includes("NO DEFAUT ADVISER FOR POLE")) {
        return "Aucun conseiller par défaut dans le Pôle choisi"
    }
    else if (error.includes("0 IS MISSING")) {
        return "Il manque le 0 dans le numéro de téléphone"
    }
    else if (error.includes("PHONE ALREADY USED")) {
        return "Téléphone déjà utilisé sur une autre fiche"
    }
    else if (error.includes("MAIL ALREADY USED")) {
        return "Mail déjà utilisé sur une autre fiche"
    }
    else if (error.includes("SOCIETY NAME ALREADY EXIST")) {
        return "Ce nom de société existe déjà"
    }
    else if (error.includes("MUST BE A NEW SOCIETY")) {
        return "Ce n'est pas une nouvelle société"
    }
    else if (error.includes("YOU CAN NOT CHOOSE A DECLINED SOCIETY")) {
        return "Vous ne pouvez pas choisir une société révoqué par l'Admin"
    }
    else if (error.includes("CANNOT ADD COLLABORATOR IN SAME POLE")) {
        return "Vous ne pouvez pas ajouter un collaborateur de votre Pôle"
    }
    else if (error.includes("ADVISER COLLABORATOR LINK ALERADY EXIST")) {
        return "Vous êtes déjà lié à ce collaborateur"
    }
    else if (error.includes("ADVISER COLLABORATOR LINK NOT EXIST")) {
        return "Ce lien collaborateur n'existe pas"
    }
    else if (error.includes("ID_DOSSIER n'existe pas")) {
        return "Ce dossier n'existe pas"
    }
    else if (error.includes("ID_JUSTIFICATIF n'existe pas")) {
        return "Ce justificatif n'existe pas"
    }
    else if (error.includes("NO DOCUMENT TO DOWNLOAD")) {
        return "Aucun document à télécharger"
    }
    else if (error.includes("LOGIN_FAILED")) {
        return "Votre identifiant cifacil n'est plus valide"
    }
    else if (error.includes("cnx04 - identification incorrecte")) {
        return "Votre identifiant n'est pas ou plus valide"
    }
    
    else if (error.includes("le fichier n'est pas accessible")) {
        return "Les fichiers ne sont pas accessible"
    }
    else if (error.includes("Cannot query field")) {
        return "Veuillez vider le cache de votre navigateur ctrl+r"
    }
    else if (error.includes("YOU MUST DEFINE AN AGENCY")) {
        return "Vous devez choisir une Agence"
    }
    else if (error.includes("SPONSOR HAS NO ADVISER")) {
        return "Le parrain n'est lié à aucun conseiller"
    }
    else if (error.includes("ADVISER_IS_NOT_AVAILABLE")) {
        return "Le conseiller choisi ne correspond pas au pôle des prospects"
    }
    else if (error.includes("SEND_BILLING_NO_FUNDS_RELEASED")) {
        return "Les fonds de ce prospect ne sont pas débloqués"
    }
    else if (error.includes("ERROR_CORRUPTED_FILE")) {
        return "Impossible de charger ce fichier"
    }
    

    else if (error.includes("GetNewsletterCampaignID No campaign with id =")) {
        let errors = error.split("with id =")
        if (errors?.length >= 2) {
            let campID = errors[1]
            return `La campagne ${campID} n'est pas encore ou plus accessible sur Mailjet`
        }
        return `La campagne n'est pas encore ou plus accessible sur Mailjet`
    }
    else if (error.includes("GetNewsletterCampaignID EMPTY LIST")) {
        return "La campagne n'est pas encore ou plus accessible sur Mailjet"
    }

    

    
 
    
    else if (error.includes("NO INTERNET CONNECTION")) {
        let errors = error.split("/aws/file?url=")
        if (errors?.length >= 2) {
            let url = decodeURL(errors[1])
            return `Le fichier est trop lourd : ${url}`
        }
        return 'Un des fichiers est trop lourd'
    }

    else if (error.includes("invalid UUID length")) {
        return null
    }




    else {
        return `Une erreur est survenue :${user_id} ${moment().format("le YYYY-MM-DD à HH:mm")}`
    }

}


export default globalError